import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "@emotion/styled"
import tw from "twin.macro"
import prop from "ramda/src/prop"
import isEmpty from "ramda/src/isEmpty"

import {
  getCityName,
  getStateName,
  getPlaces,
  getPlaceName,
  getPlaceStreet,
  getPlaceInformation,
} from "../utils/places"

import Header from "../components/header/Header"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import H1 from "../components/H1"
import Divider from "../components/Divider"
import ButtonPrimary from "../components/ButtonPrimary"

const getMarkdownRemark = prop("markdownRemark")

const Wrapper = styled.div`
  ${tw`mt-20 mb-10`}
`

const WrapperPlace = styled.div`
  ${tw`text-paragraph mb-10`}
`
const NamePlace = styled.div`
  ${tw`font-bold text-2xl`}
`
const Address = styled.div`
  ${tw`text-lg`}
`

const P = styled.p`
  ${tw`text-paragraph mb-5`}
`

const Flex = styled.div`
  ${tw`md:flex md:justify-between md:items-center`}
`

const GhostButton = styled.button`
  ${tw`bg-transparent py-2 px-2 text-lg rounded-md focus:outline-none focus:shadow-primary-opacity-60 border border-transparent hover:bg-gray-100 hover:border-gray-400 hidden md:block`}

  color: #156ba3;
`

const ButtonSmallScreen = styled(ButtonPrimary)`
  ${tw`md:hidden`}
`

function NoInformation({ place }) {
  return (
    <>
      <P>Ops... ainda não temos nenhuma informação.</P>

      <P>
        {" "}
        Se você conhece um local, considere ajudar outros concurseiros
        adicionando informações.
      </P>

      <ButtonPrimary
        onClick={() =>
          navigate("cadastrar-local", {
            state: {
              city: place,
            },
          })
        }
      >
        Adicionar um local
      </ButtonPrimary>
    </>
  )
}

export default function Local({ data }) {
  const city = React.useMemo(() => getCityName(getMarkdownRemark(data)), [data])
  const state = React.useMemo(() => getStateName(getMarkdownRemark(data)), [
    data,
  ])
  const places = React.useMemo(() => getPlaces(getMarkdownRemark(data)), [data])

  return (
    <Layout>
      <SEO title={`${city} - ${state}`} />
      <Header />
      <Wrapper>
        <Flex>
          <H1>
            {city} - {state}
          </H1>

          {!isEmpty(places) && (
            <GhostButton
              onClick={() =>
                navigate("cadastrar-local", {
                  state: {
                    city: `${city} - ${state}`,
                  },
                })
              }
            >
              Adicionar um local
            </GhostButton>
          )}
        </Flex>
        <Divider />
        {isEmpty(places) && <NoInformation place={`${city} - ${state}`} />}

        {places.map(place => {
          return (
            <WrapperPlace key={getPlaceName(place)}>
              <NamePlace>{getPlaceName(place)}</NamePlace>
              <Address>
                <div>{getPlaceStreet(place)}</div>
                <div>{getPlaceInformation(place)}</div>
              </Address>
            </WrapperPlace>
          )
        })}

        {!isEmpty(places) && (
          <ButtonSmallScreen
            smallScreen
            onClick={() =>
              navigate("cadastrar-local", {
                state: {
                  city: `${city} - ${state}`,
                },
              })
            }
          >
            Adicionar um local
          </ButtonSmallScreen>
        )}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
      }
      parent {
        ... on File {
          relativeDirectory
        }
      }
    }
  }
`
